export default {
  data () {
    return {
      checkAll: false,
      isIndeterminate: false
    }
  },
  computed: {
    selected () {
      return this.collection.filter(item => item.selected)
    },
  },
  methods: {
    handleCheckAllChange() {
      this.collection = this.collection.map(item => {
        item.selected = this.checkAll
        return item
      })
      this.isIndeterminate = false
    },
    onChangeCollect() {
      let len = this.collection.length
      let selectedLen = this.collection.filter(item => item.selected).length
      this.checkAll = len === selectedLen
      this.isIndeterminate = selectedLen > 0 && selectedLen < len
    }
  }
}
