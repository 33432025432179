<template>
  <div>
    <div class="select">
      <p>全部收藏店铺</p>
      <p>
        <!-- <span class="compete" @click="isOpt = false">批量操作</span> -->
        <span >
          <!-- <el-checkbox
            class="allselect"
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
          >全选</el-checkbox> -->
          <i class="cancel" @click="cancel">
            <img src="../../../assets/image/shou01.png">
            取消收藏
          </i>
          <!-- <i class="compete" @click="isOpt = true">完成</i> -->
        </span>
      </p>
    </div>
    <div class="section">
      <!-- <collect :collections="collection" @change="onChangeCollect"></collect> -->
      <!-- <collect  @change="onChangeCollect"></collect> -->
        <div class="container">
      <div  v-for="(item, index) in collection" :key="index">


        <div class="list"  @click="onClick(item,index)">
            <div class="name"><img class="menus handShpae" :src="item.logoUrl | imagePath" alt srcset @click="goshop(item.storeId)"></div>
            <div class="list_center">
            <p class="list_center_name" >
              <span @click="goshop(item.storeId)" class="handShpae">{{item.storeName}}</span>
              <span class="bgSpan"></span>
            </p>
            <p class="list_center_addr">{{item.address}}</p>
            <!-- <p class="list_center_addr">{{}}</p> -->
            <p class="goShop" @click="goshop(item.storeId)">进入店铺</p>
            <!-- <img class="list_center_v" :src="../../assets/image/renzheng.png"/> -->
            </div>
            <div class="list_right">
              <div v-for="(item1,i1) in item.productList" :key="i1">
                  <img :src="item1.coverUrl | imagePath" @click.stop="goCase1(item1.caseId,item.storeId)"/>
              </div>
            <!-- <img :src="item.cover1 | imagePath" v-show="item.cover1!=''" @click.stop="goCase1(item.id1,item.storeId)">
            <img :src="item.cover2 | imagePath" v-show="item.cover1!=''" @click.stop="goCase2(item.id2,item.storeId)"> -->
            </div>
            <div class="collect_select" v-show="item.selected">
            </div>
            <p class="collect_select_img" v-show="item.selected"></p>
            <!-- <div class="collect_select" v-show="isIndex==index">
            </div>
            <p class="collect_select_img" v-show="isIndex==index"></p> -->
          </div>


        </div>
    </div>


      <!-- 以下是分页 -->
      <div class="pagtion">
          <el-pagination
          @size-change="sizeChangePage"
          @current-change="currentChangePage"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalSize"
          >
          </el-pagination>
      </div>
      <!--  -->
    </div>
  </div>
</template>
<script>
import CollectMixin from './collect.mixin'
export default {
      name: "shopCollect",
      mixins: [ CollectMixin ],
  data() {
    return {
       page: 1, //当前第几页
      rows: 10, //每页默认显示几条
      totalSize:0, //总条数
      arr:[],
      isIndex:"",
      collection:[], //保存列表数据的数组

      isOpt: true,
      currentPage: 1,
      param:{}
      // collection: [
      //   // {
      //   //   name: "南京华333航商务服务",
      //   //   address: "江苏省 南京市 鼓楼区",
      //   //   dis: "15.2",
      //   //   isRes: true,
      //   //   selected: false,
      //   //   img1: "../../assets/image/chanpintu.png",
      //   //   img2: "../../assets/image/chanpintu.png"
      //   // },
      //   // {
      //   //   name: "南京华航商务服务",
      //   //   address: "江苏省 南京市 鼓楼区",
      //   //   dis: "15.2",
      //   //   isRes: true,
      //   //   selected: false,
      //   //   img1: "../../assets/image/chanpintu.png",
      //   //   img2: "../../assets/image/chanpintu.png"
      //   // }
      // ]
    };

  },
  created(){
      this.getList();
  },
  methods: {
    goCase1(id1,storeId){ //
      this.$router.push({
        path:"/caseDetail",
        query:{
          storeId:storeId,
          id:id1
        }
      })
    },
    goCase2(id2,storeId){ //
      this.$router.push({
        path:"/caseDetail",
        query:{
          storeId:storeId,
          id:id2
        }
      })
    },
    //以下分页
    //选择每页显示条数触发
     sizeChangePage(val) {
        let self = this;
        self.rows = val;
        // console.log(`每页 ${val} 条`);
        self.getList(self.rows); //请求接口
     },
    //选择第几页触发
     currentChangePage(val) {
    // console.log(`当前页: ${val}`);
        let self = this;
        self.page = val;
        self.getList(self.page);
     },
    goshop(storeId){
        this.$router.push({
          name:"shopPage",
          params:{
            id:storeId
          }
        })
    },
     getList(){ //获取收藏列表信息
     let Params={page:this.page,rows:this.rows}
      this.$http.get('collectStore/findByUserId',{ params: Params }).then(res => {
        // debugger
        this.collection=res.data.data.data;
        this.totalSize=res.data.data.totalSize; //总条数
        if(res.data.data.totalSize=="0"){
          this.totalSize=0;
        }
      })
    },
    onClick(item,i) {
      this.isIndex=i;
      this.isSelected=true
      // console.log(111,item,!item.selected)
      this.$set(item, 'selected', !item.selected)
      if(item.selected){ //选中了列表店铺
      this.arr.push(item.storeId)
}
      else{
        for(let i=0;i<=this.arr.length;i++){
            if(item.storeId==this.arr[i]){
                this.arr.splice(i,1)
            }
        }
      }
      // this.$emit('change', item)
    },
    // 跳转到详情
    goDetailPage() {
      this.$router.push({
        path: "/infoDetail"
      });
    },
    cancel(){
        if(this.arr.length==0){
           this.$message({
            message: '请选择要取消的店铺！',
            type: 'warning'
          });
          return;
        }
        this.param={"ids":this.arr}
      this.$http.post('/collectStore/deleteById', this.param).then(res => { //删除收藏的店铺
      if(res.data.code==0||res.data.code==200){
         this.$message({
          message: '已取消收藏！',
          type: 'success'
        });
          this.getList()
      }
      })
    },

    handleCheckAllChange(){
      console.log(this.checkAll,222)
    },
    handleSizeChange(val) {
      //分页
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    }
  }
};
</script>
<style lang="scss" scoped>
.select{ //店铺收藏界面的取消收藏模块

display: flex;
justify-content: space-between;
 i{
   cursor: pointer !important;
 }
}
.section{
  padding-bottom: 10px;
}

.container {
  margin-top: 30px;
  // background: #fff;
  height: auto;

  .list {
    padding: 20px;
    height: 178px;
    border: solid 1px #e5e5e5;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    >div{
      margin-right: 20px;
    }
    .name{
      display: flex;
      width: 140px;
      height: 140px;
      flex: 0;
      justify-content: center;
      align-items: center;
      img{
        display: block;
        width: 110px;
        height: 110px;
      }
    }
    .collect_select{
      width: 100%;
      height: 178px;
      position: absolute;
      top: 0;
      left:0;
      background: #666;
      opacity: 0.5;
      z-index: 100;
      &_img{
        position: absolute;
        right: 0;
        top:0;
        width: 40px;
        height:40px;
        background: url('../../../assets/image/moren.png') no-repeat;
        background-size: 40px 40px;
        z-index: 1200;
      }
    }
    .list_center{
      position: relative;
      width: 220px;
      padding-top: 5px;
      &_name{
        width: 100%;
        color: #333;
        font-size: 18px;
        line-height: 45px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      &_addr{
        width: 100%;
        color: #666;
        font-size: 14px;
        line-height: 28px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;

      }
      .goShop{
        width: 100px;
        height: 30px;
        background-color: #d82229;
        border-radius: 2px;
        font-size: 14px;
        color: #fff;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        margin-top: 16px;
      }
      &_v{
        display: inline-block;
        width: 16px;
	      height: 18px;
        position: absolute;
        top: -5px;
        right: -10px;
      }

      .list_center_name{
        display: flex;
        .bgSpan{
        display: block;
        width: 100%;
          background: url("../../../assets/image/renzheng.png") no-repeat;
          right: 0;
          top: -5px;
          margin-left: 5px;
        }
      }
    }
    .list_right{
      width: 520px;
      height:140px;
      display: flex;
      // background: red;
      img{
        display: block;
        width: 250px;
        height: 140px;
        margin-right: 21px;
        flex-shrink: 0;
        cursor: pointer;
      }
    }
  }
}

.handShpae{
  cursor: pointer;
}
// 以下分页样式
.pagtion{
  display: flex;
  justify-content: center;
  margin-top: 55px;
  margin-bottom: 55px;
}
</style>

