<template>
  <div class="pro-collect">
    <div class="pro-collect__opt">
      <!-- <div class="pro-collect__opt-label">筛选:</div> -->
      <div class="pro-collect__opt-content">
        <div class="pro-collect__opt-tabs">
          <el-radio-group v-model="filter" size="mini">
            <el-radio-button label="all" @click.native="getAll">在售中</el-radio-button>
            <el-radio-button label="xiajia" @click.native="getObtained">已下架</el-radio-button>
          </el-radio-group>
        </div>

      </div>
    </div>
    
    <!-- 以下是收藏产品展示区域 -->
    <div class="pro-collect__list">
       
      <div v-for="(item, index) in List"  :key="index" v-if="isShelf">
        <div class="pro-collect-item"  @click="detail(item.productId,item.storeId)">
            <div class="pro-collect-item__img">
              <img :src="item.pcUrl | imagePath">
              <div class="pro-collect-item__toolbar">
                <button type="button" @click.stop="goshop(item.storeId)">进入店铺</button>
                <button type="button" @click.stop="onCancelCollect(item.id)">取消收藏</button>
              </div>
            </div>
            <h2 class="pro-collect-item__title">{{item.productName}}</h2>
            <p class="pro-collect-item__price">￥{{item.price |price}}</p>
       </div>
      </div>

      <div v-for="(item1, index1) in List1"  :key="index1" v-if="!isShelf">
        <div class="pro-collect-item pro-collect-item1" @mouseenter="enter(index1)" @mouseleave="leave()">
            <div class="pro-collect-item__img">
              <div class="ObtainedWrap">已下架</div>
              <img :src="item1.pcUrl | imagePath">
              
            </div>
            <h2 class="pro-collect-item__title">{{item1.productName}}</h2>
            <p class="pro-collect-item__price">￥{{item1.price |price}}</p>
            <!-- <div>删除</div> -->

              <div class="layer">
                <button  @click.stop="onCancelCollect1(item1.id)"  :class="activeClass == index1 ? 'active' : ''" :key="index1">删除该产品</button>
              </div>
        </div>
      </div>

    </div>


       <div class="pagtion">
          <el-pagination
          @size-change="sizeChangePage"
          @current-change="currentChangePage"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalSize"
          >
          </el-pagination>
      </div>
  </div>
</template>
<script>

export default {
  data() {
      return {
      activeClass: -1,
      List:[],
      List1:[],//已下架产品列表
      filter: 'all',
       page: 1, //当前第几页
      rows: 10, //每页默认显示几条
      totalSize:0, //总条数
      isShelf:true, //是否上架
      };
  },
  components: {
  },
  created(){
    this.getcollerlist();
  },
  methods: {
    getItem(index){
    this.activeClass = index;
    },
      // 商家首页跳转到产品服务商列表
    detail(id,storeId) {
      this.$router.push({
        path: '/productDetail',
        query: {
          pid: id,
          sid: storeId
        }
      })
    },

    enter(index1){
      this.activeClass = index1;
    },
    leave(){
      this.activeClass = -1;

    },
    //以下分页
    //选择每页显示条数触发
     sizeChangePage(val) {
        let self = this;
        self.rows = val;
        // console.log(`每页 ${val} 条`);
        self.getcollerlist(self.rows); //请求接口


        // self.getcollerlist(self.rows,); //请求接口
     },
    //选择第几页触发
     currentChangePage(val) {
    // console.log(`当前页: ${val}`);
        let self = this;
        self.page = val;
        self.getcollerlist(self.page);
     },
    goshop(storeId){
        this.$router.push({
          name:"shopPage",
          params:{
            id:storeId
          }
        })
    },
     
     //点击全部宝贝
    getAll(){
      this.isShelf=true;
      this.getcollerlist();
    },
    getObtained(){
      this.isShelf=false;
       this.$http.get('/collectProduct/findByUserId?page='+this.page+'&rows='+this.rows+'&state='+this.isShelf).then(res=>{
            const {data} = res.data.data
            this.List1 = data
            this.totalSize=res.data.data.totalSize; //总条数
        })
    },
    // 取消收藏产品
    onCancelCollect (id) {
      let Params={ids:[id]}
       this.$http.post('/collectProduct/deleteById',Params).then(res=>{
          if(res.data.code==0||res.data.code==200){
            this.getcollerlist();
            this.$message({
              message: '该产品取消收藏成功!',
              type: 'success'
            });
          }
        //  
       })
    },
    onCancelCollect1 (id) {
     console.log(id)
      let Params={ids:[id]}
       this.$http.post('/collectProduct/deleteById',Params).then(res=>{
          if(res.data.code==0||res.data.code==200){
            this.isShelf=false;
            this.$http.get('/collectProduct/findByUserId?page='+this.page+'&rows='+this.rows+'&state='+this.isShelf).then(res=>{
                const {data} = res.data.data
                this.List1 = data
                this.totalSize=res.data.data.totalSize; //总条数
            })
            this.$message({
              message: '该产品删除成功!',
              type: 'success'
            });
          } 
       })
    },
      getcollerlist(){
          this.$http.get('/collectProduct/findByUserId?page='+this.page+'&rows='+this.rows+'&state='+this.isShelf).then(res=>{
              const {data} = res.data.data
              this.List = data
              this.totalSize=res.data.data.totalSize; //总条数
          })
      },
  }
};
</script>

<style lang="scss">
.pro-collect {
  width: 100%;
  clear: both;
  background: #fff;

  &__opt {
    display: flex;
    margin: 19px;

    &-label {
      font-size: 16px;
      color: #333;
      width: 60px;
      font-weight: 400;
      line-height: 38px;
    }

    &-content {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  &__opt-tabs {
    flex: 1;
  }

  &__opts {
    flex: 1;
    text-align: right;
  }

  &__list {
    width: 100%;
    margin: 19px;
    overflow: hidden;
  }

  &__pagination {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 28px 0;
  }
}










.pro-collect__list{
    margin:0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 3px;
    >div{
      margin-right: 20px;
      margin-bottom: 15px;
    }
    >:nth-child(4n){
      margin-right: 0;
    }
    .pro-collect-item {
      position: relative;
      width: 223px;
      height: 290px;
      float: left;
      cursor: pointer;
      &:hover{
            box-shadow:3px 3px 3px #eee,3px -3px 3px #eee,-3px 3px 3px #eee,-3px -3px 3px #eee;
          .pro-collect-item__toolbar {
            display: block;
          }
      }

      &.is-checkbox {
        .pro-collect-item__checkbox {
          display: block;
        }
      }

      // 下架
      &--sold-out {
        position: relative;
      }

      img{
        height: 225px;
      }
      &__img {
        width: 100%;
        height: 225px;
        position: relative;

        img {
          width: 100%;
        }
      }

      &__toolbar {
        width: 100%;
        height: 36px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        display: none;

        button {
          width: 50%;
          height: 36px;
          line-height: 36px;
          display: inline-block;
          white-space: nowrap;
          cursor: pointer;
          -webkit-appearance: none;
          text-align: center;
          box-sizing: border-box;
          outline: none;
          font-weight: 500;
          -webkit-user-select: none;
          font-size: 14px;
          color: #fff;
          font-weight: 500;
          background: none;
          z-index: 2;
          border: none;
          border-right: 1px solid #fff;

          &:hover {
            background: #d82229;
          }
        }
      }

      &__title {
        margin: 8px 0;
        padding: 0 15px;
        font-size: 16px;
        font-weight: 400;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__price {
        font-size:18px;
        color: #FF0036;
        font-weight:400;
        padding: 0 15px;
      }

      &__sold-out {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 225px;
        height: 100%;
        background:rgba(153,153,153, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__sold-out-w {
        width:75px;
        height:75px;
        line-height: 75px;
        text-align: center;
        background:rgba(28,6,6,0.4);
        border-radius:50%;
        font-size:16px;
        font-weight:400;
        color: #fff;
      }

      &__checkbox {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 225px;
        height: 100%;
        display: none;
        background:rgba(0,0,0, 0.4);
        text-align: right;

        .el-checkbox__inner {
          width: 22px;
          height: 20px;
          background: #666;
          border-color: #666;

          &::after {
            left: 7px;
            width: 5px;
            height: 10px;
            transform: rotate(45deg) scaleY(1);
          }
        }
      }
    }


    .pro-collect-item1{
      cursor:default;
      border: 1px solid #e5e5e5;
      // background:rgba(0,0,0, 0.5) transparent;
      &:hover{
         box-shadow:none;

      }
      
      .ObtainedWrap{
        z-index: 1;
        width: 100px;
        height: 100px;
        background: rgba(51,51,51, 0.5);
        color: #fff;
        position: absolute;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
        border-radius: 50%;
        text-align: center;
        line-height: 100px;
      }
      .layer{
        width: 100%;
        height: 100%;
        background: rgba(238, 238, 238, 0.5);
        position: absolute;
        top: 0;
        button{
          width:90px;
          height: 35px;
          background: #d82229;
          border: none;
          outline: none;
          color:#fff;
          position: absolute;
          right: 10px;
          bottom: 10px;
          cursor: pointer;
          display: none;
        }
        .active{
          display: block;
        }
      }
    }
}

.el-button--success{
        color: #FFF;
    background-color: #d82229;
    border-color: #d82229;
}
.el-button--success:hover{
      background: #d82229;
    }

    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background: #d82229;
        color: #fff;
        border-color: #d82229;
        webkit-box-shadow: none;
        box-shadow: none;
    }

  //按钮划过重置样式
   .el-radio-button__inner{
        &:hover{
            color: #606266;
        }
    }
 .is-disabled .el-radio-button__inner{
    &:hover{
        color: #c0c4cc;
    }
}

//element button-radio双击出现蓝色阴影
 .el-radio-button__inner{
  -moz-user-select:none;/*火狐*/
  -webkit-user-select:none;/*webkit浏览器*/
  -ms-user-select:none;/*IE10*/
  /*-khtml-user-select:none;!*早期浏览器*!*/
  user-select:none;
}

.el-tabs__item:focus.is-active.is-focus:not(:active) {
    -webkit-box-shadow: none !important;
    box-shadow: 1px;
  }
  .pro-collect__opt{
    margin-left: 0;
  }

  .pagtion{
  display: flex;
  margin-top:70px;
  justify-content: center;
}

</style>

