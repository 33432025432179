<template>
  <div class="content">
    <div class="main">
      <div class="main-r">
        <el-tabs v-model="activeName" @tab-click="onTabClick">
          <el-tab-pane label="产品收藏" name="proCollect">
          </el-tab-pane>
          <el-tab-pane label="店铺收藏" name="shopCollect">
          </el-tab-pane>
        </el-tabs>
        <router-view>
        </router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeName: this.$route.name
    }
  },
  watch: {
    '$route' (val) {
      this.activeName = val.name
    }
  },

  methods: {
    onTabClick (val) {
      this.$router.push({name: val.name})

    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/common";
@mixin icon($m) {
  $url: "../../../assets/image/" + $m;
  @include bgImage($url);
  background-size: contain;
}
* {
  margin: 0;
  padding: 0;
}
h2,
ul,
li {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
.main{
  padding:20px;
  background: #fff;
}
/deep/ .el-tabs__nav-wrap::after{
  display: none;
}
.content {
  width: 100%;
  background: #f5f5f5;
  font-size: 15px;
  /deep/ .main {
    margin: 0 auto;
    font-size: 15px;
    
    .main-l {
      width: 181px;
      background: #fff;
      float: left;
    }
    /deep/ .main-r {
      // width: 1000px;/

      .section {
        // width: 920px;
        height: 100%;
        margin: 0 auto;
      }
      .select {
        display: flex;
        display: -webkit-flex; /* Safari */
        justify-content: space-between;
        // padding: 0 18px;
        p {
          display: inline-block;
        }
      }
      .allselect {
        margin-right: 20px;
      }
      .cancel {
        // margin-right:  20px;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .compete {
        display: inline-block;
        padding: 0 5px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        color: #666;
        background-color: #f4f4f4;
        border-radius: 2px;
        border: solid 1px #e5e5e5;
      }
      // ------以下是头部tab切换导航样式-----
      .el-tabs__nav {
        margin-left: 40px;
      }
      .el-tabs el-tabs--top {
        margin-top: 20px;
      }
      .el-tabs__header {
        margin-top: 20px;
      }
      .el-tabs__item:hover {
        color: #d82229;
      }
      .el-tabs__active-bar {
        background: #d82229;
      }
      .el-tabs__item.is-active {
        color: #d82229;
      }
      .el-tabs__nav-wrap::after {
        height: 0;
      }
      #tab-first {
        background: none;
      }
      // ------以上是头部tab切换导航样式-----

      .pagination {
        width: 537px;
        height: 40px;
        margin: 0 auto;
        margin-top: 60px;
      }
      //以上是分页器样式
    }
  }
}

/deep/ .el-tabs__nav{
  margin-left: 0 !important;
}
</style>
